import React from 'react'
import ImageGallery from './ImageGallery';

function OurImageGallery() {
    const imageUrls = ['/images/card1.jpg', '/images/card2.jpg', '/images/slider1.jpg', '/images/bg2.jpg'];
    const showImg =['/images/show1.jpg','/images/show2.jpg','/images/show3.jpg','/images/show4.jpg']
    const constImg = ['/images/const1.jpg','/images/const2.jpg','/images/const3.jpg','/images/const4.jpg']
  return (
    <>
    <div className='py-5'>
        <h1 className='text-center'>Showroom</h1>
      <ImageGallery imageUrls={showImg} />
    </div>

    <div className=''>
        <h1 className='text-center'>Construction</h1>
      <ImageGallery imageUrls={constImg} />
    </div>

    <div className=''>
        <h1 className='text-center'>Other</h1>
      <ImageGallery imageUrls={imageUrls} />
    </div>
    </>
  )
}

export default OurImageGallery
