import React from 'react'
import { Link } from 'react-router-dom';

function AahiBuildTech() {
  return (
    <div>
      <div className="">
      <div className="buildtech">
      <div className="bg_image_1">
          <div className="content">
          <h3>AAHI BUILDTECH CO.</h3>
          </div>

        </div>

        <section>
          <div className="container py-5">
                <h1 className="py-5" >
                  About AAHI Buildtech Co
                </h1>
                <p style={{ textAlign: "justify" }} >
                  Lorem ipsum dolor sit amet consectetur adipisicing elit. Ad
                  fugiat qui amet iste! Tempore eum soluta quidem molestiae
                  aliquam odit nisi non adipisci. Deserunt, placeat accusantium
                  ducimus molestias quaerat laudantium laborum inventore id
                  commodi, repellat, eum cumque nemo expedita. Laborum optio
                  doloribus culpa ullam ea, libero vero quia earum dolorum!
                </p>
              
              <div className="row justify-content-between px-5 py-5">
              <h1 className="text-center pb-5">OUR OTHER COMPANIES</h1>
              <div className="col-12 col-md-6 ">
              <div
                  className="card mb-3 card-color shadow"
                  
                  style={{ borderRadius: "5px" }}
                >
                  <div className="card-body">
                    <h5 className="card-title">AAHI Enterprises</h5>
                    <p className="card-text ">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.<Link className="mx-3 text-light" to="/enterprise">Read More</Link>
                    </p>
                  </div>
                </div>
                <div
                  className="card mb-3 card-color shadow"
                  
                  style={{ borderRadius: "5px" }}
                >
                  <div className="card-body">
                    <h5 className="card-title ">AAHI Implex Company</h5>
                    <p className="card-text ">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.<Link className="mx-3 text-light" to="/implex">Read More</Link>
                    </p>
                  </div>
                </div>
                </div>
              <div className="col-12 col-md-6" >
                
                <div
                  className="card mb-3 card-color shadow"
                  
                  style={{ borderRadius: "5px" }}
                >
                  <div className="card-body">
                    <h5 className="card-title ">AAHI Freight&Logistic Co.</h5>
                    <p className="card-text ">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.<Link className="mx-3 text-light" to="/logistic">Read More</Link>
                    </p>
                  </div>
                </div>
                <div
                  className="card card-color shadow"
                  
                  style={{ borderRadius: "5px" }}
                >
                  <div className="card-body">
                    <h5 className="card-title ">UV Construction</h5>
                    <p className="card-text ">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.<Link className="mx-3 text-light" to="/construction">Read More</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            </div>
        </section>
      </div>
    </div>
    </div>
  )
}

export default AahiBuildTech
