import React from "react";
import { Link } from "react-router-dom";

function Business() {
  return (
    <div>
      <section className="our-business">
        <div className="bg_image_1">
          <div className="content">
            <h3>OUR BUSINESS</h3>
          </div>
        </div>
      </section>
      <section className="our-business-us section">
        <div className="container py-5">
          <div className="row row-cols-1 row-cols-md-1 row-cols-lg-2">
            <div className="col">
              <div className="inner">
                <p style={{ textAlign: "justify" }}>
                  AAHI-GROUP has carefully structured its businesses into eight
                  verticals to focus on product and geography strategy. Our
                  two-way supply chain capabilities, coupled with on the ground
                  warehousing, logistics and distribution capabilities, makes
                  AAHI-GROUP a global conglomerate.
                </p>
              </div>
            </div>
            <div className="col">
              <div className="container ">
                <div className="accordion shadow" id="accordionExample">
                  <div className="accordion-item ">
                    <h2 className="accordion-header" id="headingOne">
                      <button
                        className="accordion-button collapsed show card-color"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseOne"
                        aria-expanded="true"
                        aria-controls="collapseOne"
                      >
                        UV Construction
                      </button>
                    </h2>
                    <div
                      id="collapseOne"
                      className="accordion-collapse collapse "
                      aria-labelledby="headingOne"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <strong> UV Construction</strong> Lorem ipsum dolor sit
                        amet consectetur adipisicing elit. Ad sunt accusamus
                        aperiam architecto reprehenderit voluptatibus odit
                        expedita. Nesciunt similique minus perspiciatis minima
                        fugit harum laudantium quo natus veritatis? Tempore ipsa
                        placeat aliquid omnis corporis odio laborum perferendis
                        error, nisi possimus architecto optio voluptas maiores
                        natus non rem veniam beatae ab!
                        <br />
                        <Link
                          type="button"
                          to="/construction"
                          className="btn btn-dark"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingTwo">
                      <button
                        className="accordion-button collapsed card-color"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseTwo"
                        aria-expanded="false"
                        aria-controls="collapseTwo"
                      >
                        AAHI Enterprises
                      </button>
                    </h2>
                    <div
                      id="collapseTwo"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingTwo"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <strong>AAHI Enterprises</strong>
                        Lorem ipsum dolor sit amet consectetur adipisicing elit.
                        Ad sunt accusamus aperiam architecto reprehenderit
                        voluptatibus odit expedita. Nesciunt similique minus
                        perspiciatis minima fugit harum laudantium quo natus
                        veritatis? Tempore ipsa placeat aliquid omnis corporis
                        odio laborum perferendis error, nisi possimus architecto
                        optio voluptas maiores natus non rem veniam beatae ab!
                        <br />
                        <Link
                          type="button"
                          to="/enterprise"
                          className="btn btn-dark"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingThree">
                      <button
                        className="accordion-button collapsed card-color"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseThree"
                        aria-expanded="false"
                        aria-controls="collapseThree"
                      >
                        AAHI Implex Company
                      </button>
                    </h2>
                    <div
                      id="collapseThree"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingThree"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <strong>AAHI Implex Company</strong> Lorem ipsum dolor
                        sit amet consectetur adipisicing elit. Ad sunt accusamus
                        aperiam architecto reprehenderit voluptatibus odit
                        expedita. Nesciunt similique minus perspiciatis minima
                        fugit harum laudantium quo natus veritatis? Tempore ipsa
                        placeat aliquid omnis corporis odio laborum perferendis
                        error, nisi possimus architecto optio voluptas maiores
                        natus non rem veniam beatae ab!
                        <br />
                        <Link
                          type="button"
                          to="/implex"
                          className="btn btn-dark"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFour">
                      <button
                        className="accordion-button collapsed card-color"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFour"
                        aria-expanded="false"
                        aria-controls="collapseFour"
                      >
                        AAhi Freight & Logistic Co.
                      </button>
                    </h2>
                    <div
                      id="collapseFour"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFour"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <strong>AAhi Freight & Logistic Co.</strong>Lorem ipsum
                        dolor sit amet consectetur adipisicing elit. Ad sunt
                        accusamus aperiam architecto reprehenderit voluptatibus
                        odit expedita. Nesciunt similique minus perspiciatis
                        minima fugit harum laudantium quo natus veritatis?
                        Tempore ipsa placeat aliquid omnis corporis odio laborum
                        perferendis error, nisi possimus architecto optio
                        voluptas maiores natus non rem veniam beatae ab!
                        <br />
                        <Link
                          type="button"
                          to="/logistic"
                          className="btn btn-dark"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div className="accordion-item">
                    <h2 className="accordion-header" id="headingFive">
                      <button
                        className="accordion-button collapsed card-color"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#collapseFive"
                        aria-expanded="false"
                        aria-controls="collapseFive"
                      >
                        AAHI Buildtech Co.
                      </button>
                    </h2>
                    <div
                      id="collapseFive"
                      className="accordion-collapse collapse"
                      aria-labelledby="headingFive"
                      data-bs-parent="#accordionExample"
                    >
                      <div className="accordion-body">
                        <strong> AAHI Buildtech Co.</strong> Lorem ipsum dolor
                        sit amet consectetur adipisicing elit. Ad sunt accusamus
                        aperiam architecto reprehenderit voluptatibus odit
                        expedita. Nesciunt similique minus perspiciatis minima
                        fugit harum laudantium quo natus veritatis? Tempore ipsa
                        placeat aliquid omnis corporis odio laborum perferendis
                        error, nisi possimus architecto optio voluptas maiores
                        natus non rem veniam beatae ab!
                        <br />
                        <Link
                          type="button"
                          to="/buildtech"
                          className="btn btn-dark"
                        >
                          Read More
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section></section>
    </div>
  );
}

export default Business;
