import React from "react";

function Testimonial() {
  return (
    <section className="container pb-5">
      <div class="row d-flex justify-content-center">
        <div class="col-md-10 col-xl-8 text-center">
          <h1 class="mb-4">TESTIMONIALS</h1>
          <p class="mb-4 pb-2 mb-md-5 pb-md-0"></p>
        </div>
      </div>

      <div class="row text-center d-flex align-items-stretch">
        <div class="col-md-4 mb-5 mb-md-0 d-flex align-items-stretch">
          <div class="card testimonial-card ">
            <div class="card-up card-color"></div>
            <div class="avatar mx-auto bg-white">
              <img
                src="https://cdn.pixabay.com/photo/2023/04/28/23/32/ai-generated-7957457_1280.png"
                class="rounded-circle img-fluid"
                alt=""
              />
            </div>
            <div class="card-body">
              <h4 class="mb-4">Mark Wood</h4>
              <hr />
              <p class="dark-grey-text mt-4">
                <i class="bx bxs-quote-alt-left pe-2"></i>"Working with AAHI
                Group of Companies has been transformative for our business.
                Their expertise in import-export and logistics has streamlined
                our operations, propelling us into new markets seamlessly."
                <i class="bx bxs-quote-alt-right px-2"></i>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-5 mb-md-0 d-flex align-items-stretch">
          <div class="card testimonial-card">
            <div class="card-up card-color"></div>
            <div class="avatar mx-auto bg-white">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRgUB2TQjPK6a1gvhXjianRKw3iikY6yQ3lrnsmkQL3dA&s"
                class="rounded-circle img-fluid"
                alt=""
              />
            </div>
            <div class="card-body">
              <h4 class="mb-4">Robart Smith</h4>
              <hr />
              <p class="dark-grey-text mt-4">
                <i class="bx bxs-quote-alt-left pe-2"></i>"In a world where
                reliability is paramount, AAHI Group stands out. Their logistics
                solutions have revolutionized our supply chain, delivering cost
                savings and efficiency beyond measure."
                <i class="bx bxs-quote-alt-right px-2"></i>
              </p>
            </div>
          </div>
        </div>
        <div class="col-md-4 mb-0 d-flex ">
          <div class="card testimonial-card">
            <div class="card-up card-color"></div>
            <div class="avatar mx-auto bg-white">
              <img
                src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTYWPRwbTboyzH2-PchwT0qcKUupH4Zf_jrREBvBbRk0NhNjxWPqCXk7lIfnQ&s"
                class="rounded-circle img-fluid"
                alt=""
              />
            </div>
            <div class="card-body">
              <h4 class="mb-4">Commins Smith</h4>
              <hr />
              <p class="dark-grey-text mt-4">
                <i class="bx bxs-quote-alt-left pe-2"></i>"Efficiency,
                reliability, and innovation are the hallmarks of AAHI Group. Their logistics solutions have transformed our
                supply chain, delivering cost savings and improved service
                levels across the board."
                <i class="bx bxs-quote-alt-right px-2"></i>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Testimonial;
