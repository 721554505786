import React from "react";

function Footer() {
  return (
    <div>
      <footer className="text-center text-lg-start footer text-dark">
        <section className="d-flex justify-content-between copyright py-3">
          <div className="px-4">
            <span>Get connected with us </span>
          </div>
        </section>
        <section className="footer">
          <div className="container text-center text-md-start mt-5">
            <div className="row mt-3">
              <div className="col-md-12 col-lg-12 col-xl-4 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">
                  AAHI GROUP OF COMPANIES
                </h6>
                <img
                  src="/images/logo7.png"
                  alt=""
                  style={{
                    height: "150px",
                    // marginLeft: "20%",
                    // paddingTop: "10px",
                  }}
                />
                <p style={{ textAlign: "justify" }}>
                  AAHI Group Of Companies Lorem ipsum dolor sit amet consectetur
                  adipisicing elit. Quod, tenetur recusandae similique incidunt,
                  voluptate expedita earum porro nulla qui totam maxime
                  asperiores optio velit. Accusantium earum enim recusandae quia
                  suscipit?
                </p>
              </div>

              <div className="col-md-4 col-lg-3 col-xl-3 mx-auto mb-md-0 mb-4">
                <p>
                  <h6 className="fw-bold">Address</h6>Office No. 703, DLF Tower
                  A <br/>Jasola District Center
                  <br />
                  New Delhi-110044<br/>INDIA
                </p>
                <p>
                  <h6 className="fw-bold">Mail</h6>
                  info@example.com
                </p>
                <p>
                  <h6 className="fw-bold">Call</h6>
                  (011) 1234567809
                </p>
                <p>
                  <h6 className="fw-bold">Timing</h6>
                  Mon-Sat: 10am – 7pm
                </p>
              </div>
              <div className="col-md-4 col-lg-2 col-xl-2 mx-auto mb-4">
                <h6 className="text-uppercase fw-bold">LINKS</h6>
                <p>
                  <a href="/" >
                    Home
                  </a>
                </p>
                <p>
                  <a href="/ourpeople" >
                    Team
                  </a>
                </p>
                <p>
                  <a  href="/blogs">
                    Blogs
                  </a>
                </p>
                <p>
                  <a  href="/imagegallery">
                    Image Gallery
                  </a>
                </p>
                <p>
                  <a  href="/videogallery">
                    Video Gallery
                  </a>
                </p>
                <p>
                  <a  href="/contact">
                    Contact Us
                  </a>
                </p>
                <p>
                  <a  href="/career">
                    Career
                  </a>
                </p>
              </div>
            </div>
          </div>
        </section>
        <div className="text-center copyright p-3">
          Copyright ©2024 AAHI Group All Rights Reserved <br />
          <strong>Powered By</strong>{" "}
          <a
            style={{ textDecoration: "none",color:'white' }}
            href="https://abarissoftech.com/"
          >
            Abaris Softech
          </a>
        </div>
      </footer>

      {/* <footer>
        <section className="footer py-5">
          <div className="container px-5 ">
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4">
              <div className="col">
                <h3>Location</h3>
                <p>Office No. 703,DLF Tower A,Jasola District Center,New Delhi-110044, INDIA
                </p>
              </div>
              <div className="col"> <h3>Call Us</h3> <p>+91 9123456789</p></div>
              <div className="col"><h3>Mail Us</h3> <p>info@example.com</p></div>
              <div className="col "><h3>Follow Us</h3><i className='bx bxl-instagram-alt'></i><i className='bx bxl-facebook-circle'></i><i className='bx bxl-linkedin-square'></i><i className='bx bxl-twitter' ></i></div>
            </div>
          </div>
        
        <div class="container">
  <footer class="py-3 my-4">
    <ul class="nav justify-content-center border-top pb-3 mb-3">
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Home</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Features</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">Pricing</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">FAQs</a></li>
      <li class="nav-item"><a href="#" class="nav-link px-2 text-muted">About</a></li>
    </ul>
  </footer>
</div>
</section>
        <div
            className="container-fluid copyright py-3"
          >
            <div className="text-center">
            Copyright ©2024 AAHI Group  All Rights Reserved <br />
            <strong>Powered By: </strong>
            <a   style={{textDecoration:'none',color:'white'}} href="https://abarissoftech.com/">Abaris Softech</a>
            </div>
          </div>
      </footer> */}
    </div>
  );
}

export default Footer;
