import React, { useState } from 'react'
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './imageslider.css'
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";

const ImageSlider = () => {

    const isSmallScreen = window.innerWidth <=600;

    const[slideIndex,setSlideIndex]=useState(0);

    const NextArrow = ({ onClick }) => {
        return (
          <div className="arrow next" onClick={onClick}>
            <FaArrowRight />
          </div>
        );
      };
      const PrevArrow = ({ onClick }) => {
        return (
          <div className="arrow prev" onClick={onClick}>
            <FaArrowLeft />
          </div>
        );
      };
    
      const settings = {
        dots:true,
        infinite: true,
        lazyload: true,
        speed: 300,
        slidesToShow:  isSmallScreen ? 1 : 3,
        slidesToScroll: 1,
        autoplay: true,
        centerMode: true,
        beforeChange:(current,next)=>setSlideIndex(next),
        nextArrow: <NextArrow />,
        prevArrow: <PrevArrow />,
      };
      const image = [
        { img: "/images/pexels-athena-3588036.jpg" },
        { img: `/images/pexels-min-an-1230157.jpg` },
        { img: `/images/pexels-pixabay-37837.jpg` },
        { img: `/images/pexels-sevenstorm-juhaszimrus-439416.jpg` },
      ];
  return (
    <div className='container pt-5'>
        <div className="img-slider">
            <Slider{...settings}>
                {
                    image.map((img,index)=>(
                        <div className={ index === slideIndex ? 'img-slide img-slide-active':'img-slide'} key={index}>
                            <img src={img.img} alt="" />
                        </div>
                    ))
                }
            </Slider>
        </div>
    </div>
  )
}

export default ImageSlider
