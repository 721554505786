import React, { useEffect } from "react";
// import ImageGallery from "./ImageGallery";
// import VideoPlayerModal from "./VideoPlayerModal";
import { Link } from "react-router-dom";
import AOS from "aos";
import "aos/dist/aos.css";
import Testimonial from "./Testimonial";
import { BsBuildings } from "react-icons/bs";
import { HiOutlineBuildingOffice2, HiOutlineTruck } from "react-icons/hi2";
import { MdOutlineRealEstateAgent } from "react-icons/md";
import { IoMdGlobe } from "react-icons/io";
import { FaCode } from "react-icons/fa";
import ImageSlider from "./ImageSlider";

function Home() {
  // const videoSource = 'src/video/video.mp4';
  useEffect(() => {
    AOS.init({ duration: "1500", delay: "100" });
  }, []);

  

  return (
    <div className="">
      <div className="home">
        <div className="bg_image_1">
          <ul data-aos="fade-up">
            <li>
              <Link to="/construction">UV Construction</Link>
            </li>
            <li>
              <Link to="/enterprise">AAHI Enterprises</Link>
            </li>
            <li>
              <Link to="/implex">AAHI Implex Company</Link>{" "}
            </li>
            <li>
              <Link to="/logistic">AAHI Freight & Logistic Co.</Link>{" "}
            </li>
            <li>
              <Link to="/buildtech">AAHI Buildtech Co.</Link>{" "}
            </li>
          </ul>
          <div className="background-container" data-aos="fade-up">
            <div className="overlay-container p-2">
              <h1
                className="animated bounceInDown "
                style={{ animationDelay: ".3s" }}
              >
                OUR BUSINESS
              </h1>
              <br />
              <p
                className="animated bounceInDown"
                style={{ animationDelay: ".2s" }}
              >
                With expertise spanning import-export, real estate, enterprise
                logistics, and beyond, our group of companies is a powerhouse of
                innovation and opportunity.
              </p>
            </div>
          </div>
        </div>

        <section>
          <div className="container py-5">
            <h1
              className="py-5"
              style={{ color: "#ff4500" }}
              data-aos="fade-up"
            >
              ABOUT AAHI GROUP OF COMPANIES
              <hr style={{ padding: "2px", width: "11.5%" }} />
            </h1>
            <p style={{ textAlign: "justify",fontSize:'20px' }} data-aos="fade-up">
              AAHI group of companies is a dynamic force in the realms of
              import-export, real estate, enterprise logistics, and beyond. With
              a commitment to innovation, customer satisfaction, and ethical
              business practices, we lead the way in diverse industries, shaping
              the future with integrity and excellence."
            </p>{" "}
            <strong style={{ textAlign: "justify",fontSize:'20px'}} data-aos="fade-up">
              {" "}
              "Empowering Growth Across Industries: Our Diverse Portfolio
              Shines!{" "}
            </strong>
            <p style={{ textAlign: "justify",fontSize:'20px' }} data-aos="fade-up">
              {" "}
              <br />
              With expertise spanning import-export, real estate, enterprise
              logistics, and beyond, our group of companies is a powerhouse of
              innovation and opportunity. We pride ourselves on delivering
              exceptional service, fostering sustainable practices, and driving
              growth in every venture we undertake. Together, we redefine
              industry standards and create lasting value for our customers and
              communities worldwide."
            </p>
          </div>
        </section>

        <section className="service section" id="service">
          <h2
            className="heading"
            style={{ color: "#ff4500", fontSize: "40px" }}
            data-aos="fade-up"
          >
            Our Group
            <hr style={{ padding: "2px", width: "11.5%" }} />
          </h2>

          <div className="service-container">
            <div className="service-box" data-aos="fade-up">
              <h1 className="px-4">
                <BsBuildings color="white" />
              </h1>
              <h4> UV Construction</h4>
              <p>
                Utilizing advanced technologies, we discover and extract
                petroleum resources to meet global energy demands.
              </p>
            </div>

            <div className="service-box" data-aos="fade-up">
              <h1 className="px-4">
                <HiOutlineBuildingOffice2 color="white" />
              </h1>
              <h4> AAHI Enterprises</h4>
              <p>
                Facilitating the buying and selling of gold & Diamond, ensuring
                secure and transparent transactions in the global market.
                <br />
              </p>
            </div>

            <div className="service-box" data-aos="fade-up">
              <h1 className="px-4">
                <IoMdGlobe color="white" />
              </h1>
              <h4>AAHI Implex Co.</h4>
              <p>
                Facilitating smooth imports and exports across borders,
                connecting businesses to diverse Group markets.
              </p>
            </div>
            <div className="service-box" data-aos="fade-up">
              <h1 className="px-4">
                <HiOutlineTruck color="white" />
              </h1>
              <h4>AAHI Freight & Logistic Co.</h4>
              <p>
                Facilitating the buying and selling of gold & Diamond, ensuring
                secure and transparent transactions in the global market.
              </p>
            </div>
            <div className="service-box" data-aos="fade-up">
              <h1 className="px-4">
                <MdOutlineRealEstateAgent color="white" />
              </h1>
              <h4> AAHI Buildtech Co.</h4>
              <p>
                Facilitating the buying and selling of gold & Diamond, ensuring
                secure and transparent transactions in the global market.
              </p>
            </div>
            <div className="service-box" data-aos="fade-up">
              <h1 className="px-4">
                <FaCode color="white" />
              </h1>
              <h4> AAHI Software Co.</h4>
              <p>
                Elevate Your Style, Embrace Luxury – Our Showrooms Redefine
                Elegance, One Exquisite Display at a Time
              </p>
            </div>
          </div>
        </section>

        <section>
          <hr />
          <div className="container py-5">
            <h1 className="text-center" data-aos="fade-up">
              OUR STORIES
            </h1>
            <div className="row row-cols-1 row-cols-md-2 row-cols-lg-3 ">
              <div className="col py-4">
                <div
                  className="card card-color mx-auto shadow p-2"
                  data-aos="fade-up"
                  style={{ width: "22rem", borderRadius: "5px" }}
                >
                  <img
                    src="/images/card2.jpg"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text ">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col py-4">
                <div
                  className="card card-color mx-auto shadow p-2"
                  data-aos="fade-up"
                  style={{ width: "22rem", borderRadius: "5px" }}
                >
                  <img
                    src="/images/card2.jpg"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text ">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col py-4">
                <div
                  className="card mx-auto card-color shadow p-2"
                  data-aos="fade-up"
                  style={{ width: "22rem", borderRadius: "5px" }}
                >
                  <img
                    src="/images/card2.jpg"
                    className="card-img-top"
                    alt="..."
                  />
                  <div className="card-body">
                    <h5 className="card-title">Card title</h5>
                    <p className="card-text ">
                      Some quick example text to build on the card title and
                      make up the bulk of the card's content.
                    </p>
                  </div>
                </div>
              </div>
              
            </div>
          </div>
        </section>
        <section>
          <hr />
          <div className="py-5" data-aos="fade-up">
            <h1 className="text-center" data-aos="fade-up">
              IMAGE GALLERY
            </h1>
            <ImageSlider/>
          </div>
          <div class="d-flex justify-content-center pb-5" data-aos="fade-up">
            <button type="button" class="btn btn-dark">
              <Link
                to="/imagegallery"
                style={{ textDecoration: "none", color: "white" }}
              >
                View More..
              </Link>
            </button>
          </div>
          <hr />
          <div className="py-5">
            <Testimonial />
          </div>
        </section>

        <div
          id="carouselExampleCaptions"
          className="carousel slide"
          data-bs-ride="carousel"
        >
          <div className="carousel-indicators">
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="0"
              className="active"
              aria-current="true"
              aria-label="Slide 1"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="1"
              aria-label="Slide 2"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="2"
              aria-label="Slide 3"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="3"
              aria-label="Slide 4"
            ></button>
            <button
              type="button"
              data-bs-target="#carouselExampleCaptions"
              data-bs-slide-to="4"
              aria-label="Slide 5"
            ></button>
          </div>
          <div className="carousel-inner">
            <div className="carousel-item active">
              <img
                src="/images/slider4.jpg"
                className="d-block w-100"
                alt="..."
                style={{ height: "600px" }}
              />
              <div className="carousel-caption top-0">
                <h5>
                  Elevate Your Style, Embrace Luxury – Our Showrooms Redefine
                  Elegance, One Exquisite Display at a Time
                </h5>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="/images/slider2.jpg"
                className=" w-100"
                alt="..."
                style={{ height: "600px" }}
              />
              <div className="carousel-caption top-0">
                <h5>
                  Turning Dreams into Addresses – Elevate Your Life with Our
                  Real Estate Expertise
                </h5>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="/images/slider3.jpg"
                className="d-block w-100"
                alt="..."
                style={{ height: "600px" }}
              />
              <div className="carousel-caption top-0">
                <h5>
                  Building Excellence, Crafting Futures – Your Vision, Our
                  Construction
                </h5>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="/images/slider1.jpg"
                className="d-block w-100"
                alt="/images/slider4.jpg"
                style={{ height: "600px" }}
              />
              <div className="carousel-caption top-0">
                <h5>
                  Global Excellence in Import-Export – Connecting Markets,
                  Redefining Trade
                </h5>
              </div>
            </div>
            <div className="carousel-item">
              <img
                src="/images/slider5.jpg"
                className="d-block w-100"
                alt="/images/slider5.jpg"
                style={{ height: "600px" }}
              />
              <div className="carousel-caption top-0">
                <h5>
                  Impex Innovations – Redefining Trade Dynamics with Global
                  Excellence
                </h5>
              </div>
            </div>
          </div>
          <button
            className="carousel-control-prev"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="prev"
          >
            <span
              className="carousel-control-prev-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Previous</span>
          </button>
          <button
            className="carousel-control-next"
            type="button"
            data-bs-target="#carouselExampleCaptions"
            data-bs-slide="next"
          >
            <span
              className="carousel-control-next-icon"
              aria-hidden="true"
            ></span>
            <span className="visually-hidden">Next</span>
          </button>
        </div>
      </div>
    </div>
  );
}

export default Home;
