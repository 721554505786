import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
function AboutUs() {
  const NextArrow = ({ onClick }) => {
    return (
      <div className="arrow next" onClick={onClick}>
        <FaArrowRight />
      </div>
    );
  };
  const PrevArrow = ({ onClick }) => {
    return (
      <div className="arrow prev" onClick={onClick}>
        <FaArrowLeft />
      </div>
    );
  };

  const settings = {
    infinite: true,
    lazyload: true,
    speed: 300,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    centerMode: true,
    centerPadding: 0,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };
  const image = [
    { img: "/images/pexels-athena-3588036.jpg" },
    { img: `/images/pexels-min-an-1230157.jpg` },
    { img: `/images/pexels-pixabay-37837.jpg` },
    { img: `/images/pexels-sevenstorm-juhaszimrus-439416.jpg` },
  ];
  return (
    <div>
      <section className="about">
        <div className="bg_image_1">
          <div className="content">
            <h3>AAHI GROUP OF COMPANIES</h3>
          </div>
        </div>
      </section>
      <section className="about-us section">
        <div className="container py-5">
          <div className="inner">
            <p>
              AAHI group of companies has developed into a global player with a
              diverse portfolio of expertise across multiple industries,
              encompassing Agricultural inputs, logistics, merchandising and
              processing, supply chain optimization, digital transformation and
              energy. Our passion lies in the upliftment of farming communities;
              an unhindered commitment since establishment. Our promoters
              identified three challenges that supply chains in Africa faced and
              created a globalized business model around solutions addressing
              these obstacles.
            </p>
          </div>
        </div>
        <div className="container">
          <div className="row row-cols-1 row-cols-md-1 row-cols-lg-2">
            <div className="col px-5 py-2">
              <div className="card card-color shadow">
                <div className="card-body ">
                  <h1 className="card-title" style={{ textAlign: "center" }}>
                    <i className="bx bx-bullseye"></i>
                  </h1>
                  <h2
                    className="card-subtitle mb-2 "
                    style={{ textAlign: "center" }}
                  >
                    Vision
                  </h2>
                  <p className="card-text">
                    "Forging Futures, Uniting Industries: Our Vision Guides
                    Innovation Across Import-Export, Real Estate, Logistics, and
                    Beyond."
                  </p>
                </div>
              </div>
            </div>
            <div className="col px-5 py-2">
              <div className="card card-color shadow">
                <div className="card-body">
                  <h1 className="card-title " style={{ textAlign: "center" }}>
                    <i className="bx bx-target-lock"></i>
                  </h1>
                  <h2
                    className="card-subtitle mb-2 "
                    style={{ textAlign: "center" }}
                  >
                    Purpose
                  </h2>
                  <p className="card-text">
                    "Driving Global Connectivity, Fostering Sustainable Growth:
                    Our Purposeful Mission Unites Import-Export, Real Estate,
                    Logistics, and Beyond, Enriching Communities Worldwide."
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="business">
        <h1
          style={{
            textAlign: "center",
            paddingBottom: "50px",
            paddingTop: "50px",
          }}
        >
          OUR HISTORY
        </h1>
        <div className="container pb-5">
          <Slider {...settings}>
            {image.map((img) => (
              <div className="container px-5">
                <div className="row row-cols-1 row-cols-md-2 row-cols-lg-4 g-4 p">
                  <div className="col business-card1">
                    <img
                      src={img.img}
                      className="card-img-top rounded-2"
                      alt="..."
                    />
                  </div>
                  <div className="col">
                    <div className="card business-card shadow">
                      <div className="card-body">
                        <h5 className="card-title">2000-2005</h5>
                        <p className="card-text">
                          Lorem ipsum dolor sit amet, consectetur adipisicing
                          elit. Eveniet accusantium id velit dolore, similique
                          dolores aut corporis consequuntur odit neque molestiae
                          maxime beatae explicabo? Non repellat cumque
                          repellendus quisquam minus aspernatur dolorem at.
                          Commodi possimus voluptatem aliquid natus impedit
                          omnis quod officiis tempora consequuntur neque
                          necessitatibus, dolores, optio nihil voluptate!
                        </p>
                        <a href="/" className="btn btn-primary">
                          Read More
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
}

export default AboutUs;
