import React from 'react'
import VideoPlayerModal from './VideoPlayerModal'

function OurVideoGallery() {
    const videoData = [{
        poster: "https://images.pexels.com/photos/1116035/pexels-photo-1116035.jpeg?auto=compress&cs=tinysrgb&w=600",
        video: "/videos/video1.mp4",
      },{
        poster: "https://images.pexels.com/photos/209235/pexels-photo-209235.jpeg?auto=compress&cs=tinysrgb&w=600",
        video: "/videos/video2.mp4",
      },
      {
        poster: "https://images.pexels.com/photos/159375/construction-site-build-construction-work-159375.jpeg?auto=compress&cs=tinysrgb&w=600",
        video: "/videos/video3.mp4",
      },
      {
        poster: "https://images.pexels.com/photos/193791/pexels-photo-193791.jpeg?auto=compress&cs=tinysrgb&w=600",
        video: "https://player.vimeo.com/external/203443403.sd.mp4?s=4c713d2887464e9b78b498e0137c72119f7bbd0e&profile_id=164&oauth2_token_id=57447761",
      },
    ];
  return (
    <div>
      <div className='py-5'>
        <h1 className='text-center'>OUR VIDEOS</h1>
      <VideoPlayerModal videoData={videoData} />
    </div>
    </div>
  )
}

export default OurVideoGallery
