import React,{useState} from "react";
import { Link } from "react-router-dom";
import './new.css';

function Navbar() {
  const [isNavCollapsed, setIsNavCollapsed] = useState(true);

  const handleNavCollapse = () => setIsNavCollapsed(!isNavCollapsed);

  const closeNav = () => setIsNavCollapsed(true);
  return (
    <div>
      <nav
        className="navbar navbar-expand-lg navbar-style shadow"
        
      >
        <div className="container-fluid">
          <Link
            className="navbar-brand px-5"
            to="/"
            style={{ fontWeight: "bold" }}
          >
            <img src="/images/logo7.png" alt="" style={{ height: "90px",width:'100px' }} />
          </Link>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNavDropdown"
            aria-controls="navbarNavDropdown"
            aria-expanded={!isNavCollapsed ? true : false}
            aria-label="Toggle navigation"
            onClick={handleNavCollapse}
          >
            <span className="bx bx-menu menu" ></span>
          </button>
          <div
            className={`collapse navbar-collapse ${isNavCollapsed ? "" : "show"}`} 
            id="navbarNavDropdown"
          >
            <ul className="navbar-nav px-5">
              <li className="nav-item">
                <Link className="nav-link animated fadeInRight"   onClick={closeNav} aria-current="page" to="/" 
                style={{ animationDelay: ".3s",animateDuration: "700ms"}}>
                  HOME
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link animated fadeInRight" onClick={closeNav}   to="/about" style={{ animationDelay: ".4s",animateDuration: "700ms" }}>
                  ABOUT AAHI
                </Link>
              </li>
              <li className="nav-item">
                  <Link className="nav-link animated fadeInRight" onClick={closeNav}   to="/ourbusiness"style={{ animationDelay: ".5s",animateDuration: "800ms" }}>
                    OUR BUSINESS
                  </Link>
                </li>
              <li className="nav-item">
                <Link className="nav-link animated fadeInRight" onClick={closeNav}   to="/ourpeople"style={{ animationDelay: ".6s",animateDuration: "800ms" }}>
                  OUR TEAM
                </Link>
              </li>
              <li class="nav-item dropdown">
              <Link className="nav-link dropdown-toggle animated fadeInRight"  to="/imagegallery" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false"  style={{ animationDelay: ".5s",animateDuration: "800ms" }}>
                  GALLERY
                </Link>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li><Link to='/imagegallery' class="dropdown-item text-dark" onClick={closeNav}>Image Gallery</Link></li>
            <li><Link to='/videogallery' class="dropdown-item text-dark" onClick={closeNav}>Video Gallery</Link></li>
          </ul>
        </li>
              <li className="nav-item">
                <Link className="nav-link animated fadeInRight" onClick={closeNav}   to="/career"style={{ animationDelay: ".7s",animateDuration: "800ms" }}>
                  CAREER
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link animated fadeInRight" onClick={closeNav}   to="/blogs"style={{ animationDelay: ".8s",animateDuration: "800ms" }}>
                  BLOGS
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link animated fadeInRight" onClick={closeNav}   to="/contact"style={{ animationDelay: ".9s",animateDuration: "800ms" }}>
                  CONTACT
                </Link>
              </li>
              </ul>
              <ul className="navbar-nav px-5">
              <li className="nav-item">
                <Link className="nav-link animated fadeInRight" onClick={closeNav}   to="/contact"style={{ animationDelay: ".9s",animateDuration: "800ms",fontSize:'30px' }}>
                <i class='bx bxl-facebook'></i>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link animated fadeInRight" onClick={closeNav}   to="/contact"style={{ animationDelay: ".9s",animateDuration: "800ms",fontSize:'30px' }}>
                <i class='bx bxl-twitter' ></i>
                </Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link animated fadeInRight" onClick={closeNav}   to="/contact"style={{ animationDelay: ".9s",animateDuration: "800ms",fontSize:'30px' }}>
                <i class='bx bxl-linkedin' ></i>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
}

export default Navbar;
