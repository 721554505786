import React from "react";

function Blogs() {
  return (
    <div>
      <section className="medialinks">
        <div className="bg_image_1">
          <div className="content">
            <h3>Media & Links</h3>
          </div>
        </div>
      </section>
      <section className="blog section" id="blog">
        <div className="container ">
          <div class="row justify-content-start py-5">
            <div class="col-4"><div className="news-head">
                  <img src="/images/logo2.png" alt="#" />
                </div></div>
            <div class="col-12 col-md-8 py-4"><div className="news-body">
                  <div className="news-content">
                    <div className="date">23 Aug, 2020</div>
                    <h5>
                      <a href="/">Explore The Importance Of Having A Will</a>
                    </h5>
                    <p className="text">
                      Lorem ipsum dolor a sit ameti, consectetur adipisicing
                      elit, sed do eiusmod tempor incididunt sed do incididunt
                      sed.
                    </p>
                  </div>
                </div></div>
          </div>

          <div class="row justify-content-start py-2">
            <div class="col-4"><div className="news-head">
                  <img src="/images/logo2.png" alt="#" />
                </div></div>
            <div class="col-12 col-md-8 py-4"><div className="news-body">
                  <div className="news-content">
                    <div className="date">15 Sep, 2020</div>
                    <h5>
                      <a href="/">The Impact of Social Media to the Society</a>
                    </h5>
                    <p className="text">
                      Lorem ipsum dolor a sit ameti, consectetur adipisicing
                      elit, sed do eiusmod tempor incididunt sed do incididunt
                      sed.
                    </p>
                  </div>
                </div></div>
          </div>


          <div class="row justify-content-start py-5">
            <div class="col-4"><div className="news-head">
                  <img src="/images/logo2.png" alt="#" />
                </div></div>
            <div class="col-12 col-md-8 py-4"><div className="news-body">
                  <div className="news-content">
                    <div className="date">18 Oct, 2020</div>
                    <h5>
                      <a href="/">Estate Planing for Small Business & Articles</a>
                    </h5>
                    <p className="text">
                      Lorem ipsum dolor a sit ameti, consectetur adipisicing
                      elit, sed do eiusmod tempor incididunt sed do incididunt
                      sed.
                    </p>
                  </div>
                </div></div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Blogs;
