import React from "react";

function Contact() {
  return (
    <div>
      <section className="contactus">
        <div className="bg_image_1">
          <div className="content">
            <h3> Contact Us</h3>
          </div>
        </div>
      </section>
      <section className="contact-us section1">
        <div className="">
          <div className="inner">
            <div className="row py-5">
              <div
                className="col-lg-6 col-md-6 card-color shadow"
                style={{ background: "", borderRadius: "5px" }}
              >
                <div className="contact-us-form">
                  <h5 className="contact-paraa">
                    If you have any questions please fell free to contact with
                    us.
                  </h5>
                  <form className="form">
                    <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="name"
                            placeholder="Name"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="email"
                            name="email"
                            placeholder="Email"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="phone"
                            placeholder="Phone"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="form-group">
                          <input
                            type="text"
                            name="subject"
                            placeholder="Subject"
                            required=""
                          />
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <textarea
                            name="message"
                            placeholder="Your Message"
                            required=""
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-12">
                        <div className="form-group">
                          <button className="btn btn-primary" type="submit">
                            Send
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 col-md-6 py-5">
                <div className="contact-us-left">
                  <div className="contact-info">
                    <i className="bx bx-home"></i>
                    <div className="contact-details">
                      <span className="contact-label">Head Office</span>
                      <p className="my-3">
                        Office No. 703,DLF Tower A,Jasola District Center
                        <br />
                        New Delhi-110044, INDIA
                      </p>
                    </div>
                  </div>
                  <div className="contact-info">
                    <i className="bx bx-envelope"></i>
                    <div className="contact-details">
                      <span className="contact-label">Email Address</span>
                      <p>example@example.com</p>
                    </div>
                  </div>
                  <div className="contact-info">
                    <i className="bx bx-phone"></i>
                    <div className="contact-details">
                      <span className="contact-label">Telephone</span>
                      <p>(+91) 1234567890</p>
                    </div>
                  </div>
                  <div className="contact-info">
                    <i className="bx bx-time"></i>
                    <div className="contact-details">
                      <span className="contact-label">Office Hour</span>
                      <p>Mon-Sat: 10am – 7pm</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
			<iframe title="contact map" style={{ width: "100%", height: "400px", border: 0}} src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3505.0447409317353!2d77.28765017450611!3d28.53837488835206!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1sOffice%20No.%20703%2CDLF%20Tower%20A%2CJasola%20District%20Center%2CNew%20Delhi-110044%2C%20INDIA!5e0!3m2!1sen!2sin!4v1706299818879!5m2!1sen!2sin" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Contact;
