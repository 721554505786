import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import './App.css';
import Navbar from './components/Navbar';
import Home from './components/Home';
import Footer from './components/Footer';
import AboutUs from './components/AboutUs';
import Business from "./components/Business";
import Teams from "./components/Teams";
import Career from "./components/Career";
import Blogs from "./components/Blogs";
import Contact from "./components/Contact";
import OurImageGallery from "./components/OurImageGallery";
import UvConstruction from "./components/UvConstruction";
import AahiEnterprises from "./components/AahiEnterprises";
import AahiImplexCompany from "./components/AahiImplexCompany";
import FreightLogistic from "./components/FreightLogistic";
import AahiBuildTech from "./components/AahiBuildTech";
import OurVideoGallery from "./components/OurVideoGallery";
import ImageSlider from "./components/ImageSlider";

function App() {
  return (
    <Router>
    <Navbar/>
    {/* <div className=""style={{marginTop:"100px"}}> */}
      
      <Routes>
    <Route exact path="/" element={<Home />} />
    <Route exact path="/about" element={<AboutUs />} />
    <Route exact path="/ourbusiness" element={<Business />} />
    <Route exact path="/ourpeople" element={<Teams />} />
    <Route exact path="/career" element={<Career />} />
    <Route exact path="/blogs" element={<Blogs />} /> 
    <Route exact path="/contact" element={<Contact />} /> 
    <Route exact path="/imagegallery" element={<OurImageGallery />} /> 
    <Route exact path="/videogallery" element={<OurVideoGallery />} /> 
    <Route exact path="/construction" element={<UvConstruction />} /> 
    <Route exact path="/enterprise" element={<AahiEnterprises/>} /> 
    <Route exact path="/implex" element={<AahiImplexCompany />} /> 
    <Route exact path="/logistic" element={<FreightLogistic />} /> 
    <Route exact path="/buildtech" element={<AahiBuildTech />} />
    <Route exact path="/imageslider" element={<ImageSlider />} /> 
    </Routes>
    <Footer/> 
    {/* </div> */}
    </Router>
  );
}

export default App;
